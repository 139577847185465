import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import '@fancyapps/ui/dist/fancybox.css'

import './style/home.less'

createApp(App).use(router).mount('#app')
