<template>
  <div class="home">
    <TopScreen>
      <div class="top-wrapper">
        <h1>
          Inscriptions and NFT Infrastructure<br />to Empower Web3 Builders
        </h1>
        <p class="top-desc">
          Vinci Protocol is the first cross-chain suite of inscription and NFT
          developer tools and data services supporting BRC20/CBRC20. Integrated
          with Bitcoin, Solana, Arbitrum and all EVMs.
        </p>
        <div class="top-link d-flex flex-column flex-md-row">
          <a href="https://wiki.vinci.io/" target="_blank" class="me-md-4">
            <button class="mt-3">
              <img :src="whitepaper" alt="" />
              <span>Documentation</span>
            </button>
          </a>
          <a href="https://app.vinci.io/">
            <button class="mt-3">
              <img src="../assets/img/icon-launch.svg" alt="" />
              <span>Launch Demo</span>
            </button>
          </a>
        </div>
        <!-- <div class="audit-row">
          <span>Audited by</span>
          <a href="https://www.certik.com/projects/vinci-protocol?utm_source=SkyEmblem&amp;utm_campaign=vinci-protocol&amp;utm_medium=link" target="_blank">
            <img src="../assets/img/icon-certick.png" />
          </a>
        </div> -->
      </div>
    </TopScreen>
    <section class="section-main relative">
      <div class="line">
        <img src="../assets/img/home-line.svg" alt="" class="img-fluid" />
      </div>
      <div class="protocol-container">
        <Title info="What is Vinci Protocol"></Title>
        <span class="protocol-description">
          Vinci protocol is designed to offer a comprehensive set of developer
          tools and data services for inscription and NFT projects. With Vinci,
          developers can integrate inscription and NFT solutions quickly and
          cost-effectively. Our suite of solutions includes finance, oracle,
          governance, and marketplace, providing everything developers need to
          create inscription and NFT applications in minutes.
        </span>
        <div class="protocol-bottom">
          Ordinal + Inscription = Bitcoin NFT<br />
          ERC721 + Metadata = Traditional NFT
        </div>
        <span class="protocol-line"></span>
      </div>
      <section class="app-section nft-finance">
        <div class="nft-finance-right">
          <div class="app-right">
            <Title info="Vinci Finance"></Title>
            <span
              >Vinci Protocol provides inscription and NFT backed DeFi solutions
              designed for boosting liquidity.</span
            >
          </div>
          <div class="nft-finance-btn-containner-mobile">
            <a
              v-for="item in financeMobileSlides"
              :key="item.name"
              :class="{
                'app-launch': true,
                active:
                  activeProtocol.active && item.index === activeProtocol.index
              }"
              @click="handleFinanceSlides(item.index)"
              >{{ item.title }}</a
            >
          </div>
        </div>
        <div>
          <img
            class="nft-finance-img-pc"
            v-for="item in financePCSlides"
            :key="item.name"
            :src="item.url"
            v-show="item.index === activeProtocol.index"
          />
        </div>

        <!-- <img src="../assets/img/app-screen.webp" class="screen-img" /> -->
        <div class="nft-finance-img-mobile">
          <img
            v-for="item in financePCSlides"
            :key="item.name"
            :src="item.url"
            v-show="item.index === activeProtocol.index"
          />
        </div>
        <div class="nft-finance-btn-containner-pc">
          <a
            v-for="item in financeMobileSlides"
            :key="item.name"
            :class="{
              'app-launch': true,
              active:
                activeProtocol.active && item.index === activeProtocol.index
            }"
            @click="handleFinanceSlides(item.index)"
            >{{ item.title }}</a
          >
        </div>
      </section>
      <section class="nft-oracle">
        <div class="nft-oracle-wrapper">
          <Title info="Vinci Oracle"></Title>
          <span class="nft-oracle-description">
            Vinci Protocol enables inscription and NFT data oracles to provide
            inscription price and NFT on-chain floor prices.
          </span>
          <img class="nft-oracle-img" :src="nftOracle" alt="" />
          <div class="nft-oracle-grid-container">
            <div class="nft-oracle-grid-item">
              <img
                class="nft-oracle-item-icon"
                :src="nftGovernanceIconSVG"
                alt=""
              />
              <p class="nft-oracle-item-title">Vinci Governance</p>
              <span
                >Vinci will provide a tokenized guild tool for Web3 builders to
                manage the membership for their communities automatically. With
                Vinci, the Web3 builds could create an exclusive guild for their
                loyal community members with inscription and NFT issuing and
                gating techniques.
              </span>
            </div>
            <div class="nft-oracle-grid-item">
              <img
                class="nft-oracle-item-icon"
                :src="nftCommerceIconSVG"
                alt=""
              />
              <p class="nft-oracle-item-title">Vinci Marketplace</p>
              <span>
                Vinci will create a smart marketplace SDK so the Web3 builders
                can easily integrate to provide a self-holding commerce
                interface to serve the inscription and NFT trading economy they
                are building. They could easily customize the interface UI,
                auction rules, transaction fees, loyalty fees, and trading
                services.
              </span>
            </div>
          </div>
        </div>
      </section>
      <section class="builders">
        <Title
          info="Builders Behind the<br />Inscription and NFT Builders"
        ></Title>
        <span class="builders-description">
          Vinci Protocol is building an inscription and NFT infrastructure to
          empower Web3 builders with infinitely scalable and secure systems and
          exceptional tools.
        </span>
        <div class="builders-functions-grid-container">
          <div class="builders-functions-grid-item">
            <img :src="buildersReliableSVG" alt="" />
            <p class="builders-functions-grid-item-title">Reliable</p>
            <span class="builders-functions-grid-item-des">
              Reliable and consistent access to inscription and NFT development
              is guaranteed to support your high-throughout requests.</span
            >
          </div>
          <div class="builders-functions-grid-item">
            <img :src="buildersCustomizableSVG" alt="" />
            <p class="builders-functions-grid-item-title">Customizable</p>
            <span class="builders-functions-grid-item-des">
              Offering fully customizable services to developers so they could
              design the features, user interface, and configuration completely
              to their liking.
            </span>
          </div>
          <div class="builders-functions-grid-item">
            <img :src="buildersSecureSVG" alt="" />
            <p class="builders-functions-grid-item-title">Secure</p>
            <span class="builders-functions-grid-item-des">
              Multi-parties security audits and bug bounties to minimize
              security risks.
            </span>
          </div>
          <div class="builders-functions-grid-item">
            <img :src="buildersSamelessSVG" alt="" />
            <p class="builders-functions-grid-item-title">Seamless</p>
            <span class="builders-functions-grid-item-des">
              Less than 20 lines of codes for the developers to release their
              sovereign products.
            </span>
          </div>
        </div>
        <div class="builders-interfaces-grid-container">
          <div class="builders-interfaces-grid-item">
            <p class="builders-functions-grid-item-title">APIs</p>
            <span>
              Vinci data APIs help Web3 developers build inscription and NFT
              analysis and valuation using inscription and NFT data services. We
              provide a set of endpoints that enable fetching inscription and
              NFT collections data, such as metadata, on-chain transactions,
              rarity, and trading floor price.
            </span>
            <a
              class="builders-interfaces-grid-item-btn"
              href="https://wiki.vinci.io/nft-api/"
              target="_blank"
              >Documentation</a
            >
          </div>
          <div class="builders-interfaces-grid-item">
            <p class="builders-functions-grid-item-title">SDK</p>
            <span
              >Vinci provides turnkey solutions of inscription and NFT SDK to
              Web3 builders so they can quickly focus on delivering business
              value. Vinci SDK will provide the prebuilt contract modules with
              standardized but customizable interfaces so that Web3 builders can
              deploy the contracts securely but only in several lines of code.
            </span>
            <a
              class="builders-interfaces-grid-item-btn"
              href="https://wiki.vinci.io/nft-sdk/"
              target="_blank"
              >Documentation</a
            >
          </div>
        </div>
      </section>
      <div class="community">
        <Title info="Empowered by the community"></Title>
        <div class="community-grid-container">
          <div class="box-item">
            <img src="../assets/img/community-1.svg" alt="" />
            <div class="box-info text-center">
              <h3>Vinci DAO</h3>
              <p>
                Vinci Protocol runs as a DAO from day one. VinciDAO enables VCI
                holders and their delegates to propose and vote on upgrades to
                the protocol.
              </p>
            </div>
          </div>
          <div class="box-item">
            <img src="../assets/img/community-2.svg" alt="" />
            <div class="box-info text-center">
              <h3>VCI Token</h3>
              <p>
                The VCI token will facilitate the Vinci metaverse, and serve as
                both a governance token and a utility token. It will lead to a
                flywheeler effect on the protocol.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section class="roadmap">
        <Title info="Roadmap"></Title>
        <div class="roadmap-grid-container">
          <div class="roadmap-grid-item roadmap-grid-item-phase1">
            <div class="roadmap-grid-item-title">Phase 1</div>
            <div class="roadmap-grid-item-subtitle">BTC/ETH Focus</div>
            <div class="roadmap-grid-item-description">
              Building secure and scalable inscription and NFT services for the
              Bitcoin and Ethereum ecosystem.
            </div>
          </div>
          <div class="roadmap-grid-item roadmap-grid-item-phase2">
            <div class="roadmap-grid-item-title">Phase 2</div>
            <div class="roadmap-grid-item-subtitle">Chain-agnostic</div>
            <div class="roadmap-grid-item-description">
              Expanding the inscription and NFT APIs and SDK to serve
              high-growth public chains.
            </div>
          </div>
          <div class="roadmap-grid-item roadmap-grid-item-phase3">
            <div class="roadmap-grid-item-title">Phase 3</div>
            <div class="roadmap-grid-item-subtitle">DAO Governance</div>
            <div class="roadmap-grid-item-description">
              Creating a community-driven management system to self-iterate the
              protocol.
            </div>
          </div>
        </div>
      </section>
      <div class="container vinci-mt-lg blockchains">
        <Title info="Works Seamless Across Blockchains"></Title>
        <div class="blockchains-wrapper">
          <img
            src="../assets/img/blockchains.png"
            alt=""
            class="img-fluid"
            width="790"
          />
        </div>
      </div>
      <div class="container vinci-mt-lg">
        <Title info="Supported Inscription and NFT Collections"></Title>
        <p class="mt-5">
          <img
            src="../assets/img/collections.png"
            alt=""
            class="img-fluid"
            width="790"
          />
        </p>
      </div>
    </section>
    <section>
      <div class="container vinci-mt-lg">
        <Title info="Join Our Growing Community"></Title>
        <p class="join-title">
          Stay tuned with our newsletters and exciting news.
        </p>
        <div class="social-container d-flex flex-md-row flex-column">
          <a href="https://twitter.com/vinciprotocol">
            <div class="social-item">
              <img src="../assets/img/social-twitter.svg" alt="" />
              <p>Twitter</p>
            </div>
          </a>
          <a href="https://t.me/vinciprotocol">
            <div class="social-item">
              <img src="../assets/img/social-tele.svg" alt="" />
              <p>Telegram</p>
            </div>
          </a>
          <a href="https://discord.gg/wzSASEfXa5">
            <div class="social-item">
              <img src="../assets/img/social-dis.svg" alt="" />
              <p>Discord</p>
            </div>
          </a>
          <a href="https://medium.com/@vinciprotocol">
            <div class="social-item">
              <img src="../assets/img/social-med.svg" alt="" />
              <p>Medium</p>
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

import TopScreen from '@/components/TopScreen'
import Title from '@/components/Title'

import vector from '@/assets/img/vector.svg'
import polygon from '@/assets/img/polygon.png'
import whitepaper from '@/assets/img/whitepaper.svg'
import what from '@/assets/img/what.svg'
import vcpeople from '@/assets/img/vcpeople.png'
import group1 from '@/assets/img/group1.png'
import group2 from '@/assets/img/group2.png'
import group3 from '@/assets/img/group3.png'
import group4 from '@/assets/img/group4.png'
import group5 from '@/assets/img/group5.png'
import components1 from '@/assets/img/icon-4-1.svg'
import components2 from '@/assets/img/icon-4-2.svg'
import components3 from '@/assets/img/icon-4-3.svg'
import components4 from '@/assets/img/icon-4-4.svg'
import polygoncenter from '@/assets/img/polygon-center.png'
import participants1 from '@/assets/img/participants1.svg'
import participants2 from '@/assets/img/participants2.svg'
import participants3 from '@/assets/img/participants3.svg'
import participants4 from '@/assets/img/participants4.svg'
import participants5 from '@/assets/img/participants5.svg'
import participants6 from '@/assets/img/participants6.svg'
import telegram from '@/assets/img/telegram.svg'
import twitter from '@/assets/img/twitter.svg'
import email from '@/assets/img/email.svg'
import github from '@/assets/img/github.svg'
import net from '@/assets/img/net.svg'
import m from '@/assets/img/m.svg'
import logob from '@/assets/img/logo-b.svg'
import logoc from '@/assets/img/logo-c.svg'
import logos from '@/assets/img/logos.png'
import dashboardMobile from '@/assets/home/dashboard-mobile.png'
import dashboardPC from '@/assets/home/oracle-dashboard.png'
import lendMobile from '@/assets/home/land-mobile.png'
import lendPC from '@/assets/home/oracle-land.png'
import borrowMobile from '@/assets/home/borrow-mobile.png'
import borrowPC from '@/assets/home/oracle-borrow.png'
import nftOracle from '@/assets/home/oracle-mainnet.webp'
import nftGovernanceIconSVG from '@/assets/home/nft-governance-icon.svg'
import nftCommerceIconSVG from '@/assets/home/nft-commerce-icon.svg'
import buildersReliableSVG from '@/assets/home/builders-reliable.svg'
import buildersCustomizableSVG from '@/assets/home/builders-customizable.svg'
import buildersSecureSVG from '@/assets/home/builders-secure.svg'
import buildersSamelessSVG from '@/assets/home/builders-sameless.svg'

import line from '@/assets/img/line-new.svg'

//let flip = true;
//var myModal = document.getElementById('exampleModal');
import store from '../store'

export default {
  name: 'HomeNew',
  data() {
    return {
      store,
      activeIndex: '',
      clientHeight: '',
      isShow: false,
      open: false,
      mouseenter: false,
      isTop: false,
      active: 0,
      showModal: false,
      modal: null,
      vector: vector,
      polygon: polygon,
      whitepaper: whitepaper,
      what: what,
      polygoncenter: polygoncenter,
      vcpeople: vcpeople,
      group5: group5,
      group4: group4,
      group3: group3,
      group2: group2,
      group1: group1,
      components1: components1,
      components2: components2,
      components3: components3,
      components4: components4,
      participants1: participants1,
      participants2: participants2,
      participants3: participants3,
      participants4: participants4,
      participants5: participants5,
      participants6: participants6,
      telegram: telegram,
      twitter: twitter,
      email: email,
      github: github,
      net: net,
      m: m,
      logob: logob,
      logoc: logoc,
      logos: logos,
      line: line,
      nftOracle: nftOracle,
      nftGovernanceIconSVG: nftGovernanceIconSVG,
      nftCommerceIconSVG: nftCommerceIconSVG,
      buildersReliableSVG: buildersReliableSVG,
      buildersCustomizableSVG: buildersCustomizableSVG,
      buildersSecureSVG: buildersSecureSVG,
      buildersSamelessSVG: buildersSamelessSVG,
      architecture: [
        {
          id: 1,
          isShow: false,
          title: 'Compatible with Different Smart Contracts',
          info: 'serve as the generalized solutions that will allow access to different smart contract platforms, including EVM contracts for EVM-compatible chains like Ethereum and BSC, WASM contracts, and Substrate parachain contracts, for the Polkadot Ecosystem.'
        },
        {
          id: 2,
          isShow: false,
          title: 'NFT-backed Lending and LeasingRenting Platform',
          info: 'serves as the permissionless money market with NFT assets as the collateral, including Pool and P2P mechanism. NFT holders can list their borrowing or leasing needs on the Vinci platform, which will enable money lenders and renters to respond to their requirements and liberate liquidity.'
        },
        {
          id: 3,
          isShow: false,
          title: 'Decentralized NFT-backed Perpetual ContractsDEX',
          info: 'serves as the fully non-custodial and decentralized perpetual market for NFT assets, including AMM and order book mechanism. NFT holders can deposit NFT assets, including collectibles and GameFi items, as collateral to open perpetual contracts to hedge the volatility of the NFT floor price.'
        },
        {
          id: 4,
          isShow: false,
          title: 'Extreme Efficiency and Trader-friendly VinciVM',
          info: 'serves as the technical base layer of Vinci Protocol. It consists of an off-chain Market Maker Engine and an on-chain Settlement Engine. VinciVM is the core component and can be easily deployed on multiple NFT ecosystems, to deliver an extremely efficient and low-cost trading UI/UX.'
        }
      ],
      tokenomics: [
        {
          isShow: false,
          title: 'Incentives',
          info: 'VCI plays the role of rewards for traders and market makers.'
        },
        {
          title: 'Trading Fee Discounts',
          info: 'Any trader who holds VCI tokens will be eligible for a discount on the trading fees.'
        },
        {
          isShow: false,
          title: 'Governance',
          info: 'VCI token holders can create and vote on proposals. Token holders are able to vote for the protocol upgrades and ecosystem parameter changes.'
        },
        {
          isShow: false,
          title: 'Staking to Share Trading Fees',
          info: 'All VCI holders can stake VCI tokens to the Vinci Vault to share the trading fees generated by the Vinci protocol.'
        },
        {
          title: 'Supplying Vinci Insurance Vault',
          info: 'About 20% of VCI tokens will be supplied to the Vinci Insurance Vault, to prevent insolvency by unprofitable liquidations and smart contract hacks.'
        },
        {
          isShow: false,
          title: 'Access to the Lending Market',
          info: 'Lenders and Borrowers need to pay VCI tokens to use the lending market and perform various actions, such as depositing NFT as collateral, listing loan demands and loan offers, and so on.'
        }
      ],
      participants: [
        {
          icon: participants1,
          title: 'NFT Lenders',
          info: 'NFT Lenders are the ones who lend money to the NFT Borrowers.'
        },
        {
          icon: participants2,
          title: 'VCI Stakers',
          info: 'VCI stakers stake VCI tokens in the Vinci Vault and share the trading fees from the Vinci market.'
        },
        {
          icon: participants3,
          title: 'NFT Borrowers',
          info: 'NFT Borrowers are the ones who deposit their NFT assets as collateral to borrow money.'
        },
        {
          icon: participants4,
          title: 'NFT Renters',
          info: 'NFT Renters can borrow NFTs from others to join any play to earn activities.'
        },
        {
          icon: participants5,
          title: 'Market Makers',
          info: 'Market Makers use Vinci’s trading API to provide proficient liquidity by sending cryptographically-signed off-chain order messages to the Vinci market.'
        },
        {
          icon: participants6,
          title: 'NFT Hedgers',
          info: 'NFT Hedgers are traders who are looking to protect themselves from the risk involved in NFT price movements. They look for opportunities to pass on this risk to those who are willing to bear it.'
        }
      ],
      financeMobileSlides: [
        {
          title: 'Dashboard',
          name: 'dashboard',
          href: 'https://app.vinci.io/',
          url: dashboardMobile,
          index: 1
        },
        {
          title: 'Lend',
          name: 'lendMobile',
          href: 'https://app.vinci.io/',
          url: lendMobile,
          index: 2
        },
        {
          title: 'Borrow',
          name: 'borrowMobile',
          href: 'https://app.vinci.io/',
          url: borrowMobile,
          index: 3
        }
      ],
      financePCSlides: [
        {
          title: 'Dashboard',
          name: 'dashboard',
          href: 'https://app.vinci.io/',
          url: dashboardPC,
          index: 1
        },
        {
          title: 'Lend',
          name: 'lendMobile',
          href: 'https://app.vinci.io/',
          url: lendPC,
          index: 2
        },
        {
          title: 'Borrow',
          name: 'borrowMobile',
          href: 'https://app.vinci.io/',
          url: borrowPC,
          index: 3
        }
      ],
      activeProtocol: {
        active: true,
        index: 1
      },
      activeProtocolInterval: null
    }
  },
  components: {
    Title,
    TopScreen
  },
  mounted() {
    let clientHeight = `${document.documentElement.clientHeight}`
    this.clientHeight = `${clientHeight}` + 'px'
    window.scroll({
      top: 0
    })
    // this.swichProtocolSlides();
    //document.querySelectorAll('.architecture-card').forEach(item => {
    //item.addEventListener('click',showMore())
    //})
    //this.modal = new Modal(this.$refs.exampleModal.$el);
    // this.modal = new Modal(document.getElementById('exampleModal'), {
    //   keyboard: false,
    //   backdrop: 'static'
    // })
  },
  methods: {
    handleFinanceSlides(index) {
      this.activeProtocol.active = true
      this.activeProtocol.index = index
      console.log(this.activeProtocol)
    },
    showDetail: function (item) {
      item.isShow = true
    },
    hiddenDetail: function (item) {
      item.isShow = false
    },

    mouChange(item) {
      // this.mouseenter(index) = true
      item.isShow = !item.isShow
    },
    mouChange1(item) {
      //this.mouseenter(index) = false
      item.isShow = !item.isShow
    },
    generateClassName(index) {
      return 'tokenomics-card' + (index + 1)
    },
    generateClassName1(index) {
      return 'architecture-card' + (index + 1)
    },
    goAnchor(selector) {
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth'
      })
    },

    modalOpen: function (i) {
      //let myModal = new Modal(document.getElementById('exampleModal'));
      this.modal.show()
      //this.showModal = true;
      i.isShow = true
      //this.teams = i;
      //return this.active = i;
    },
    hideModal() {
      this.modal.hide()
    },
    swichProtocolSlides() {
      this.activeProtocolInterval = setInterval(() => {
        this.activeProtocol.active = true
        if (this.activeProtocol.active && this.activeProtocol.index < 3) {
          this.activeProtocol.index++
        } else {
          this.activeProtocol.index = 1
        }
      }, 5000)
    },
    destroyProtocolInterval() {
      clearInterval(this.activeProtocolInterval)
      this.activeProtocol = { active: false, index: 0 }
      this.activeProtocolInterval = null
    }
  },
  unmounted() {
    this.destroyProtocolInterval()
  }
}
</script>
<style lang="less" scoped>
@media all and (min-width: 768px) {
  .top-desc {
    max-width: 520px;
    font-size: 20px;
  }
  .top-wrapper {
    text-align: left;
    padding-left: 7rem;
  }
}
</style>
