<template>
  <div :class="['player-wrap', {
    ['player-show']: store.showVideo
  }]">
    <div class="player-main">
      <img src="../assets/img/player-close.svg" @click="close" />
      <video
        ref="video"
        src="https://ob-public-bkt.s3.ap-northeast-1.amazonaws.com/www-res/vinci.mp4"
        controls
      />
    </div>
  </div>
</template>
<script>
import store from '../store'

export default {
  name: 'PlayerPage',
  data() {
    return {
      store
    }
  },
  methods: {
    close() {
      this.store.showVideo = false
    }
  },
  watch: {
    'store.showVideo': function(val) {
      if (val === false) {
        this.$refs.video.pause()
        this.$refs.video.currentTime = 0
      } else {
        this.$refs.video.play()
      }
    }
  }
}
</script>
<style lang="less">
.player-wrap {
  visibility: hidden;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(24, 24, 27, 0.92);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  z-index: 9999;
  opacity: 0;
  transition: all 0.2s linear;

  video {
    width: 100%;
    max-width: 960px;
  }
}
.player-main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
.player-show {
  opacity: 1;
  visibility: visible;
}
</style>
