<template>
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    class="arrow-down"
  >
    <path
      d="M130.255 321.128L512 702.872l381.745-381.745h-763.49z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<style scoped>
.arrow-down {
  margin-left: 5px;
}
</style>