<template>
  <div  class="modal fade"  id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="exampleModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content" v-for="(item,id) in activeModal" :key="id">
      <template v-if="item.isShow">
        <div class="modal-header">
          <button type="button" class="btn-close" @click="hidemodelInner(item)" aria-label="Close">Close</button>
        </div>
        <div class="modal-body" >
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <img :src="item.img" width="100"/>
              <div class="d-flex justify-content-start mt-3">
                  <div v-for="(t, index) in item.links" :key="index">
                    <div v-if="t.linkedin" class="link-bg"> 
                      <a :href="t.linkedin" target="_blank">in</a>
                    </div>
                    <div v-if="t.twitter" class="link-bg">
                      <a :href="t.twitter" target="_blank"><img src="../assets/img/twitter-m.svg"></a></div>
                  </div>
                </div>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="title">{{ item.title }}</div>
              <div class="name" v-html="item.name"></div>
              <div class="info">{{ item.info }}
              </div>
            </div>
          </div>
        </div>
      </template>
      </div>
    </div>
  </div>
</template>

<script>
//import { Modal } from 'bootstrap';

export default {
  name: 'ModalCom',
  props: ['teams'],
  computed: {
    activeModal: function () {
      return this.teams.filter(function (team) {
        return team.isShow
      })
    }
  },
  methods: {
    hidemodelInner(item){
      //console.log("內層事件被觸發");
      this.$emit("emit-hide");
      item.isShow = false;
    }
  }
}

</script>
<style lang='less' scoped>
</style>