<template>
  <div class="home">
    <TopScreen>
      <div class="top-wrapper">
        <h1>About Us</h1>
        <p class="subtitle">
          Vinci Protocol is a sum of efforts by a world-class team that is fully
          committed to building the future digital space and top advisors who
          are conversant with Web3 and inscription and NFT narratives.
        </p>
      </div>
    </TopScreen>
    <section class="section-about relative">
      <div id="team"></div>
      <div class="container">
        <div class="line">
          <img src="../assets/img/about-line.svg" alt="" class="img-fluid" />
        </div>
        <Title info="World Class Team & Advisors"></Title>
        <div class="team-container mx-auto">
          <div class="row justify-content-center">
            <div
              v-for="(item, index) in teamlist"
              :key="index"
              class="team-item col-6 col-md-4"
            >
              <div class="d-flex justify-content-between team-item-top">
                <div class="title">{{ item.title }}</div>
                <div class="d-flex justify-content-end">
                  <div v-for="(t, index) in item.links" :key="index">
                    <div v-if="t.linkedin" class="link-bg">
                      <a :href="t.linkedin" target="_blank">in</a>
                    </div>
                    <div v-if="t.twitter" class="link-bg">
                      <a :href="t.twitter" target="_blank"
                        ><img src="../assets/img/twitter-m.svg"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-between pt-3 align-items-end team-item-center"
              >
                <div v-html="item.name" class="name"></div>
                <div><img :src="item.img" width="100" /></div>
              </div>
              <div class="btn-read team-item-bottom" @click="modalOpen(item)">
                read more
              </div>
            </div>
          </div>
          <div class="about-desc">
            With battle-tested experiences and acute foresight for technological
            trends, we are en route to success.
          </div>
          <img src="../assets/img/about-logos.png" class="about-logos" />
        </div>
      </div>
    </section>
    <div class="hiring">
      <p class="hiring-des">
        We are always looking for smart contracts engineers and other talents.
        If you are interested, please send your github or resume to:
      </p>
      <p class="hiring-email">info@vinci.io</p>
    </div>
  </div>
  <team :teams="teamlist" v-on:emit-hide="hideModal"></team>
</template>

<script>
import { Modal } from 'bootstrap'
import Title from '@/components/Title'
import team from '@/components/modal'
import TopScreen from '@/components/TopScreen'

export default {
  name: 'AboutPage',
  components: {
    team,
    Title,
    TopScreen
  },
  mounted() {
    this.modal = new Modal(document.getElementById('exampleModal'), {
      keyboard: false,
      backdrop: 'static'
    })
    window.scroll({
      top: 0
    })
  },
  methods: {
    modalOpen: function (i) {
      this.modal.show()
      i.isShow = true
    },
    hideModal() {
      this.modal.hide()
    }
  },
  data() {
    return {
      showModal: false,
      modal: null,
      teams: [''][''],
      teamlist: [
        // {
        //   title: 'CEO',
        //   name: 'Florian <p>Jersch</p>',
        //   id: 1,
        //   isShow: false,
        //   readmore: 'read more',
        //   img: require('@/assets/img/team-1.png'),
        //   links: [
        //     {
        //       linkedin: 'https://de.linkedin.com/in/florian-jersch'
        //     }
        //   ],
        //   info: 'Florian has been involved in several digitalization strategies and projects in the finance and insurance industry since 2014, taking them to a new level. He joined VINCI to lead it to success with a strategy and expertise from the financial sector. He impresses with new approaches and creative ideas to get problems out of the way and find new ways to solve them. With an impressive work ethic, he is focused on making the world of NFT accessible to all and bringing the great value of NFTs to a new level.'
        // },
        {
          title: 'COO',
          name: 'Ruvini <p>Uthpala</p>',
          id: 2,
          isShow: false,
          readmore: 'read more',
          img: require('@/assets/img/team-2.png'),
          links: [
            {
              linkedin: 'https://linkedin.com/in/ruviniuthpala'
            },
            {
              twitter: 'https://twitter.com/ruuhansi'
            }
          ],
          info: 'Education: BSc(Hons) in Electrical and Electronics Engineering.I have been working on blockchain projects in various roles since 2018. That experience includes being a marketing ambassador, Global Community Manager, and Local community manager in some top-ranking projects.'
        },
        {
          title: 'CMO',
          name: 'Maks <p>Nedobor</p>',
          id: 3,
          isShow: false,
          readmore: 'read more',
          img: require('@/assets/img/team-3.png'),
          links: [
            {
              linkedin: 'https://www.linkedin.com/in/maksymnedobor/'
            }
          ],
          info: 'Fintech and blockchain product marketing lead with 5+ years of  hands-on experience.Started career in crypto back in 2017 in Hacken, and assisted in market entries and expansions for some of the well-known projects in web3. Combines strong product analytics and demand generation skills to provide an excellent user experience.'
        },
        {
          title: 'CTO',
          name: 'Bobby <p>Lau</p>',
          id: 4,
          isShow: false,
          readmore: 'read more',
          img: require('@/assets/img/team-4.png'),
          info: 'Expertise in C++, Python, Solidity, WASM, ASM. Years experience in Big Data Processing, Distributed Networking Programming, Game Server-side Programming. Strong domain knowledge of Game, Advertising Tracking, Video Rendering, Reverse Engineering.'
        },
        // {
        //   title: 'Community Director',
        //   name: 'Merthan <p>Kale</p>',
        //   id: 5,
        //   isShow: false,
        //   readmore: 'read more',
        //   img: require('@/assets/img/team-5.png'),
        //   links: [
        //     {
        //       linkedin: ''
        //     }
        //   ],
        //   info: 'The active industrial engineer has been working in the crypto marketing and community industry for years. His talent in leading various community and moderator teams has been proven many times. As Turkish ambassador of crypto marketing company Uptrend Agency, MINE Network, delta.theta, BloXmove and many others relied on his leadership, marketing and KOL management skills in the past.'
        // },
        // {
        //   title: 'Advisor',
        //   name: 'Andreas <p>Aschenbrenner</p>',
        //   id: 6,
        //   isShow: false,
        //   readmore: 'read more',
        //   img: require('@/assets/img/team-6.png'),
        //   links: [
        //     {
        //       linkedin: 'https://www.linkedin.com/in/aschenbrennerandreas'
        //     },
        //     {
        //       twitter: 'https://twitter.com/as_wonderkid'
        //     }
        //   ],
        //   info: 'Joining Crypto Space in 2014, Andreas brings a wealth of experience from marketing, DeFi and metaverses to support us. His talents in go-to-market strategy have been proven many times. He will be a groundbreaking force for us when it comes to ecosystem expansion and business development.'
        // },
        // {
        //   title: 'Advisor',
        //   name: 'Vladan <p>Falcic</p>',
        //   id: 7,
        //   isShow: false,
        //   readmore: 'read more',
        //   img: require('@/assets/img/team-7.png'),
        //   links: [
        //     {
        //       linkedin: 'https://www.linkedin.com/in/vladan-falcic-sqcap'
        //     }
        //   ],
        //   info: 'With 7 years of experience in the blockchain space, Vladan has been involved with building multiple startups, with a wide wealth of experience ranging from DeFi to NET projects. He is specialized in developing P2E and metaverse projects with the main focus on the in game economy and sustainability.'
        // },
        {
          title: 'Advisor',
          name: 'Ben <p>Lakoff</p>',
          id: 8,
          isShow: false,
          readmore: 'read more',
          img: require('@/assets/img/team-8.png'),
          links: [
            {
              linkedin: 'https://www.linkedin.com/in/benlakoff'
            },
            {
              twitter: 'https://twitter.com/benlakoff'
            }
          ],
          info: 'Background is in Finance (CFA), and active angel investor (75+ investments) in crypto since 2016. He is an advisor for a number of companies in the space and currently mentoring at The LAO, Tachyon and Kernel.'
        },
        {
          title: 'Advisor',
          name: 'Sebastian <p>Ruschel</p>',
          id: 9,
          isShow: false,
          readmore: 'read more',
          img: require('@/assets/img/team-9.png'),
          links: [
            {
              linkedin:
                'https://www.linkedin.com/in/sebastian-ruschel-217519239'
            }
          ],
          info: 'Web3 Investor, BAYC Member, NFT Enthusiast at Heart I Web3/NFT Advisor & Business Development I Owner & Manager of a NFT Fund'
        },
        {
          title: 'Advisor',
          name: 'William <p>De’Ath</p>',
          id: 10,
          isShow: false,
          readmore: 'read more',
          img: require('@/assets/img/team-10.png'),
          links: [
            {
              linkedin: 'https://ch.linkedin.com/in/williamde-ath'
            },
            {
              twitter: 'https://mobile.twitter.com/BDeath75/with_replies'
            }
          ],
          info: 'Will is an expert in communications and strategic business development, with over 25 years of experience helping businesses grow to their full potential. He has a burning passion for tech and scientific innovation to create sustainable solutions leveraging digital and data-driven technologies.'
        },
        {
          title: 'Advisor',
          name: 'Jure  <p>Mornar</p>',
          id: 11,
          isShow: false,
          readmore: 'read more',
          img: require('@/assets/img/team-11.png'),
          links: [
            {
              linkedin: 'https://www.linkedin.com/in/jure-mornar-29bab48/'
            }
          ],
          info: 'CEO & Founder at Techpad, a software development studio that helped dozens of notable blockchain projects to deliver on their vision.Being involved in the blockchain industry since its early days, Jure has been a technical advisor for multiple well-known projects in the space.'
        },
        {
          title: 'Advisor',
          name: 'Shashwat  <p>Eternal</p>',
          id: 12,
          isShow: false,
          readmore: 'read more',
          img: require('@/assets/img/team-12.png'),
          links: [
            {
              linkedin:
                'https://www.linkedin.com/in/shashwat-eternal-9818a7193/'
            }
          ],
          info: 'Shashwat has been a novelist, a founder in normie world, and a marketeer in crypto for 2+ years. He has led the marketing efforts for multiple projects, NFT3 being his third, and brings a cultural lens to everything crypto. His central conviction is that among the 3 big tech frontiers of AI, Bioengineering and Blockchain, blockchain will bring the most benign cultural progress. It is this thesis and the ideal of individual sovereignty he explores as head of Marketing for NFT3.com. Advising many other projects such as Lovelace.world and midanft.com at the same time, his journey has been one of seeking cultural correction and growth for the entire world via the emerging architecture of web3.'
        }
      ]
    }
  }
}
</script>
<style>
.section-about {
  z-index: 2;
}
.about-desc {
  text-align: center;
  padding: 156px 20px 105px;
  font-size: 18px;
  max-width: 450px;
  margin: auto;
}
.about-logos {
  margin: auto;
  width: 100%;
  max-width: 798px;
}
.hiring {
  margin: auto;
  padding-top: 167px;
  width: 398px;
  text-align: center;
}

.hiring-des {
  font-size: 14px;
  line-height: 22px;
}
.hiring-email {
  font-size: 20px;
  font-weight: 420px;
  color: #f94432;
  margin-top: 30px;
}
@media all and (min-width: 768px) {
  .top-wrapper {
    text-align: left;
    padding-left: 7rem;
  }
}
</style>
