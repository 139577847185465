<template>
  <div class="home">
    <div class="top-space"></div>
    <section class="section-top d-flex relative">
      <waveMore></waveMore>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 top-description-wrapper">
            <h1>Vinci Finance</h1>
            <p>
              Vinci Protocol provides inscription and NFT backed DeFi solutions
              designed for boosting liquidity.
            </p>
            <div class="top-link d-flex flex-column flex-md-row">
              <a
                href="https://wiki.vinci.io/nft-finance/"
                target="_blank"
                class="me-md-4"
              >
                <button class="mt-3">
                  <img src="../assets/img/whitepaper.svg" alt="" />
                  <span>Documentation</span>
                </button>
              </a>
              <a href=" https://app.vinci.io/nft-finance">
                <button class="mt-3">
                  <img src="../assets/img/icon-launch.svg" alt="" />
                  <span>Launch App</span>
                </button>
              </a>
            </div>
            <!-- <div class="audit-row">
              <span>Audited by</span>
              <img src="../assets/img/icon-certick.png" />
            </div> -->
          </div>
          <div class="col-12 col-md-6 vector">
            <img :src="vector" alt="" />
          </div>
        </div>
        <p class="polygo">
          <img :src="polygon" alt="" width="40" />
        </p>
      </div>
    </section>
    <section class="section-main relative line-start-wrapper">
      <div class="community">
        <div class="line line-finance">
          <img src="../assets/img/finance-line.svg" alt="" class="img-fluid" />
        </div>
        <div class="video-box">
          <PlayBtn @click="store.showVideo = true" />
        </div>
        <Title info="Why Vinci Multi-pool Lending Market"></Title>
        <div class="community-grid-container">
          <div class="box-item">
            <img :src="landingMarketOne" alt="" />
            <div class="box-info text-center">
              <h3>One Collection One Pool</h3>
              <p>
                You can provide any liquidity assets to any collection you
                select, building an exclusive pool to realize 100% risk
                isolation.
              </p>
            </div>
          </div>
          <div class="box-item">
            <img :src="landingMarketLimitless" alt="" />
            <div class="box-info text-center">
              <h3>Limitless Collection Listing</h3>
              <p>
                It can open one lending pool for any inscription and NFT
                collection you want. Free integration, easy listing, and
                unlimited collections.
              </p>
            </div>
          </div>
          <div class="box-item">
            <img :src="landingMarketLimitLending" alt="" />
            <div class="box-info text-center">
              <h3>Lending Pool Mechanism</h3>
              <p>
                Lending Pool Mechanism is much higher capital efficient with
                your inscription and NFT collaterals and liquidity than the
                Peer-to-peer model.
              </p>
            </div>
          </div>
          <div class="box-item">
            <img :src="landingMarketLimitNon" alt="" />
            <div class="box-info text-center">
              <h3>Non-custodial & Permissionless</h3>
              <p>
                Assets are controlled in your hands. It is a safe, open and
                secure protocol with fully auditing proving its highest security
                standards.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section class="finance">
        <div class="finance-bg" />
        <div class="finance-wrapper">
          <img class="finance-img" :src="LendBorrow" alt="" />
          <div class="description-wrapper">
            <p class="description-title">Lend, Borrow and Earn in Minutes</p>
            <ul class="description-list">
              <li>
                Deposit your inscription and NFTs as collateral to borrow
                cryptos
              </li>
              <li>Lend your cryptos and earn yields instantly</li>
              <li>Provide liquidity on platform to earn rewards</li>
            </ul>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>
<script>
import PlayBtn from '@/components/PlayBtn'
import waveMore from '@/components/waveMore'
import Title from '@/components/Title'

import vector from '@/assets/img/vector.svg'
import polygon from '@/assets/img/polygon.png'
import m from '@/assets/img/m.svg'
import landingMarketOne from '@/assets/finance/landing-market-one.svg'
import landingMarketLimitless from '@/assets/finance/landing-market-limitless.svg'
import landingMarketLimitLending from '@/assets/finance/landing-market-lending.svg'
import landingMarketLimitNon from '@/assets/finance/landing-market-non.svg'
import LendBorrow from '@/assets/home/oracle-dashboard.png'

import store from '../store'

export default {
  name: 'FinancePage',
  data() {
    return {
      store,
      activeIndex: '',
      clientHeight: '',
      isShow: false,
      open: false,
      mouseenter: false,
      isTop: false,
      active: 0,
      showModal: false,
      modal: null,
      vector: vector,
      polygon: polygon,
      m: m,
      landingMarketOne: landingMarketOne,
      landingMarketLimitless: landingMarketLimitless,
      landingMarketLimitLending: landingMarketLimitLending,
      landingMarketLimitNon: landingMarketLimitNon,
      LendBorrow: LendBorrow
    }
  },
  components: {
    waveMore,
    Title,
    PlayBtn
  },
  mounted() {
    let clientHeight = `${document.documentElement.clientHeight}`
    this.clientHeight = `${clientHeight}` + 'px'
    window.scroll({
      top: 0
    })
  },
  methods: {
    swichProtocolSlides() {
      this.activeProtocolInterval = setInterval(() => {
        this.activeProtocol.active = true
        if (this.activeProtocol.active && this.activeProtocol.index < 3) {
          this.activeProtocol.index++
        } else {
          this.activeProtocol.index = 1
        }
      }, 1500)
    },
    destroyProtocolInterval() {
      this.activeProtocol = { active: false, index: 0 }
      this.activeProtocolInterval = null
    }
  }
}
</script>

<style lang="less" scoped>
.top-description-wrapper {
  padding: 0 20px;
}
.community {
  padding: 100px 20px 220px 20px;
}

.audit-row {
  justify-content: center;
}

.finance-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  position: relative;
}

.description-wrapper {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.description-title {
  font-size: 46px;
  font-weight: 600;
  color: #f94432;
}

.finance {
  position: relative;
  padding: 133px 20px 0;
  margin: auto;
}
.finance-bg {
  position: absolute;
  height: 200px;
  width: 100%;
  background: linear-gradient(180deg, #621813 0%, rgba(0, 0, 0, 0) 100%);
  left: 0;
  top: 400px;
}
.finance-img {
  max-width: 100%;
}

.description-list {
  text-align: left;
  padding-left: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.line-finance {
  width: auto;
}

@media all and (min-width: 768px) {
  .audit-row {
    justify-content: flex-start;
  }
  .top-description-wrapper {
    padding: 0;
    text-align: left;
    padding-left: 7rem;
  }
  .finance-wrapper {
    flex-direction: row;
    max-width: 963px;
    margin: auto;
    text-align: left;
  }

  .finance-img {
    margin-right: 39px;
    max-width: 580px;
  }

  .description-list {
    padding-bottom: 0;
  }

  .description-wrapper {
    align-self: flex-start;
  }
}
</style>
