<template>
  <div class="top-space"></div>
  <section class="section-top d-flex relative">
    <waveMore />
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-7 top-text">
          <slot />
        </div>
        <div class="col-12 col-md-5 vector">
          <img :src="vector" alt="" />
        </div>
      </div>
      <p class="polygo">
        <img :src="polygon" alt="" width="40" />
      </p>
    </div>
  </section>
</template>
<script>
import vector from '@/assets/img/vector.svg';
import polygon from '@/assets/img/polygon.png';
import waveMore from '@/components/waveMore';

export default {
  components: {
    waveMore,
  },
  data() {
    return {
      polygon,
      vector,
    };
  },
};
</script>
<style>
.top-text {
  text-align: left;
}
</style>