<template>
  <div id="nav">
    <div class="top-bar" v-if="!hideTip">
      <span class="top-msg"
        >NFT infrastructure Vinci Protocol raised $2.1M in a seed funding round
        led by UOB Venture Management and SigNum Capital.</span
      >
      <a
        href="https://medium.com/@vinciprotocol/vinci-protocol-completed-2-1m-seed-round-fundraising-4d1c6f6e0de6"
        target="_blank"
        >Learn More</a
      >
      <span class="top-close" @click="closeTip">X</span>
    </div>
    <nav
      :class="[
        'navbar',
        'fixed-top',
        'navbar-expand-lg',
        {
          'nav-wrap': !hideTip
        }
      ]"
    >
      <div class="container">
        <router-link class="navbar-brand" to="/">
          <img alt="Vue logo" src="./assets/logo.svg" />
        </router-link>
        <button
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <svg
            t="1640949725193"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1270"
            width="48"
            height="48"
          >
            <path
              d="M768 373.333333H213.333333a32 32 0 0 1 0-64h554.666667a32 32 0 0 1 0 64zM768 576.426667H213.333333A32 32 0 1 1 213.333333 512h554.666667a32 32 0 0 1 0 64zM768 779.52H213.333333a32 32 0 0 1 0-64h554.666667a32 32 0 0 1 0 64z"
              p-id="1271"
              data-spm-anchor-id="a313x.7781069.0.i0"
              class=""
              fill="#ffffff"
            ></path>
          </svg>
        </button>
        <div class="navbar-blank" />
        <div class="certik-wrap">
          <div class="certik-emblem" data-id="a23f4b88">
            <a
              href="https://www.certik.com/projects/vinci-protocol?utm_source=SkyEmblem&amp;utm_campaign=vinci-protocol&amp;utm_medium=link"
            >
              <img src="./assets/home/top-right-icon.png" />
            </a>
          </div>
        </div>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNavDropdown"
        >
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>Products</span>
                <ArrowDown />
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <router-link class="dropdown-item" to="/finance">
                    Finance
                  </router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/oracle">
                    Oracle
                  </router-link>
                </li>
                <li class="disabled-link">
                  <a class="dropdown-item disable"
                    >Governance <span>Coming Soon</span></a
                  >
                </li>
                <li class="disabled-link">
                  <a class="dropdown-item disable"
                    >Commerce <span>Coming Soon</span></a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>Documentation</span> 
                <ArrowDown />
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <a
                    class="dropdown-item"
                    href="https://wiki.vinci.io/nft-api/"
                    target="_blank"
                    >API</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://wiki.vinci.io/nft-sdk/"
                    target="_blank"
                    >SDK</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>Resources</span> 
                <ArrowDown />
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <a
                    class="dropdown-item"
                    href="https://wiki.vinci.io/"
                    target="_blank"
                    >Project Wiki</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://github.com/VinciProtocol/vinci-protocol"
                    target="_blank"
                    >GitHub</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://github.com/VinciProtocol/vinci-protocol/tree/master/audits"
                    target="_blank"
                    >Audit Reports</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://docsend.com/view/bn28jmkretpiw2ym"
                    target="_blank"
                    >Brand Asset</a
                  >
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>Community</span> 
                <ArrowDown />
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <a
                    class="dropdown-item"
                    target="_blank"
                    href="https://twitter.com/vinciprotocol"
                    >Twitter</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    target="_blank"
                    href="https://t.me/vinciprotocol"
                    >Telegram</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    target="_blank"
                    href="https://discord.gg/wzSASEfXa5"
                    >Discord</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    target="_blank"
                    href="https://medium.com/@vinciprotocol"
                    >Medium</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <router-link class="nav-link" to="/about"> About Us </router-link>
              <!-- <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <a
                    class="dropdown-item"
                    target="_blank"
                    href="https://docsend.com/view/ajjfw9acnsw83ef8"
                    >Introduction</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    target="_blank"
                    href="https://docsend.com/view/2pu4sy534588irk4"
                    >Overview</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    target="_blank"
                    href="https://docsend.com/view/2kxj8nv39tm4aydz"
                    >Tokenomics</a
                  >
                </li>
                <li>
                  <span class="dropdown-item" @click="showVideo">Video</span>
                </li>
              </ul> -->
            </li>
            <li class="nav-item">
              <a
                class="nav-link d-md-block d-lg-none"
                :class="[activeIndex == 6 ? 'active' : 'default']"
                href="https://app.vinci.io/"
                >Launch App</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <router-view />
  <footer>
    <div class="container vinci-mt-lg">
      <div
        class="d-flex flex-md-row flex-column justify-content-between align-items-start align-items-md-end"
      >
        <div
          class="d-flex flex-column order-2 order-md-1 text-start newsletters"
        >
          <h4>Subscribe to Vinci Protocol</h4>
          <span class="mt-1"
            >Our newsletter covering the latest news and progress</span
          >
          <form
            :action="'https://ruby.us11.list-manage.com/subscribe/post?u=5c7bd9583e3142bce41f15192&amp;id=5c4f98b975'"
            method="post"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
          >
            <div class="d-flex newsletters-bg">
              <div class="w-full">
                <input
                  type="email"
                  name="EMAIL"
                  class="email-input"
                  placeholder="Your email address"
                  autocomplete="off"
                  required
                />
                <div
                  style="position: absolute; left: -5000px"
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_5f0b91c96bbdf35913a136639_ddfba3375e"
                    tabindex="-1"
                  />
                </div>
              </div>
              <div class="border-2 border-red-450">
                <button
                  type="submit"
                  class="submit flex items-center justify-center w-24 h-10 text-white rounded-non"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="order-1 order-md-2">
          <div class="d-flex flex-md-row flex-column">
            <div
              class="d-flex flex-column justify-content-start footer-link-item"
            >
              <p class="footer-link-label">Products</p>
              <div class="footer-link">
                <div>
                  <router-link to="/finance"> Finance </router-link>
                </div>
                <div>
                  <router-link to="/oracle"> Oracle </router-link>
                </div>
                <div class="disabled-link">
                  <a class="disable">Governance</a> <span>Coming Soon</span>
                </div>
                <div class="disabled-link">
                  <a class="disable">Commerce</a> <span>Coming Soon</span>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column justify-content-start footer-link-item"
            >
              <p class="footer-link-label">Resource</p>
              <div class="footer-link">
                <div>
                  <a target="_blank" href="https://wiki.vinci.io/"
                    >Project Wiki</a
                  >
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://github.com/VinciProtocol/vinci-protocol"
                    >GitHub</a
                  >
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://github.com/VinciProtocol/vinci-protocol/tree/master/audits"
                    >Audit Reports</a
                  >
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://docsend.com/view/bn28jmkretpiw2ym"
                    >Brand Asset</a
                  >
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column justify-content-start footer-link-item"
            >
              <p class="footer-link-label">Community</p>
              <div class="footer-link">
                <div>
                  <a target="_blank" href="https://t.me/vinciprotocol"
                    >Telegram</a
                  >
                </div>
                <div>
                  <a target="_blank" href="https://twitter.com/vinciprotocol"
                    >Twitter</a
                  >
                </div>
                <div>
                  <a target="_blank" href="https://medium.com/@vinciprotocol"
                    >Medium</a
                  >
                </div>
                <div>
                  <a target="_blank" href="https://discord.gg/wzSASEfXa5">Discord</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto footer-info">
      <div class="copyright-row">© {{ new Date().getFullYear() }}, VINCI. All Rights Reserved</div>
    </div>
  </footer>
  <Player />
</template>

<style lang="less">
#app {
  font-family: 'brandon-grotesque', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background: #000;
}
#nav {
  background: linear-gradient(180deg, #621813 0%, rgba(98, 24, 19, 0) 100%);
  .nav-link {
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    position: relative;
    &:hover {
      color: #f94432;
    }
    &.active {
      color: #f94432;
    }
    &:focus {
      border: 0;
      outline: none;
    }
  }
  .dropdown {
    margin-right: 1rem;
  }
  .dropdown-toggle {
    &::after {
      position: absolute;
      top: 18px;
    }
  }

  .certik-wrap {
    display: none;
    width: 144px;
    margin-right: 10px;
  }

  .top-right-icon {
    max-width: 113px;
  }
}
.navbar-blank {
  display: none;
}
.certik-emblem {
  display: none;
}

.navbar-nav {
  text-align: left;
  padding: 0.25rem 1rem;
}

.nav-item {
  text-align: left;
  padding: 0.25rem 1rem;
}

.footer-info {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 40px;
  padding-top: 40px;
  text-align: left;
}
.info-row {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 18px;

  a {
    display: inline-flex;
  }
}
.copyright-row {
  font-size: 14px;
  font-weight: 300;
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.7);
}

@media all and (min-width: 992px) {
  .navbar-nav,
  .nav-item {
    text-align: center;
    padding: 0;
  }
}
</style>
<script>
import ArrowDown from './components/ArrowDown.vue'
import NavRightIcon from '@/assets/home/top-right-icon.png'
import store from './store'
import Player from './components/player.vue'
import * as certik from './libs/certik'

export default {
  name: 'App',
  components: {
    Player,
    ArrowDown
  },
  data() {
    return {
      store,
      activeIndex: '',
      isTop: false,
      NavRightIcon: NavRightIcon,
      hideTip: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    certik.init()
  },
  methods: {
    closeTip() {
      localStorage.setItem('hideTip2', '1')
      this.hideTip = true
    },
    showVideo() {
      this.store.showVideo = true
    },
    goAnchor(selector) {
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth'
      })
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let navoffsetTop = 274

      if (scrollTop > navoffsetTop) {
        document.querySelector('.navbar').style.backgroundColor = '#000'
      } else {
        //if (()) {
        document.querySelector('.navbar').style.backgroundColor = 'transparent'
        //}
      }
    }
  }
}
</script>
