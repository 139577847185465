<template>
  <div class="home">
    <div class="top-space"></div>
    <section class="section-top d-flex relative">
      <waveMore></waveMore>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 top-description-wrapper">
            <h1>Vinci Oracle</h1>
            <p>
              Vinci Protocol enables inscription and NFT data oracles providing
              on-chain prices.
            </p>
            <div class="top-link d-flex flex-column flex-md-row">
              <a
                href="https://wiki.vinci.io/nft-oracle/"
                target="_blank"
                class="me-md-4"
              >
                <button class="mt-3">
                  <img src="../assets/img/whitepaper.svg" alt="" />
                  <span>Documentation</span>
                </button>
              </a>
              <a href="https://app.vinci.io/nft-oracle">
                <button class="mt-3">
                  <img src="../assets/img/icon-launch.svg" alt="" />
                  <span>Launch App</span>
                </button>
              </a>
            </div>
            <!-- <div class="audit-row">
              <span>Audited by</span>
              <img src="../assets/img/icon-certick.png" />
            </div> -->
          </div>
          <div class="col-12 col-md-6 vector">
            <img :src="vector" alt="" />
          </div>
        </div>
        <p class="polygo">
          <img :src="polygon" alt="" width="40" />
        </p>
      </div>
    </section>
    <section class="section-main relative line-start-wrapper">
      <div class="line line-finance">
        <img src="../assets/img/oracle-line.svg" alt="" class="img-fluid" />
      </div>
      <div class="community">
        <Title info="Why Vinci Price Oracle?"></Title>
        <div class="roadmap-grid-container">
          <div
            class="roadmap-grid-item oracle-grid-item oracle-grid-item-phase1"
          >
            <img src="../assets/oracle/oracle-Multiple-Data-Sources.svg" />
            <div class="roadmap-grid-item-title">Multiple Data Sources</div>
            <div class="roadmap-grid-item-description">
              All inscription and NFT price data is sourced from multiple
              inscription and NFT marketplaces by directly monitoring their
              floor price APIs.
            </div>
          </div>
          <div
            class="roadmap-grid-item oracle-grid-item oracle-grid-item-phase2"
          >
            <img src="../assets/oracle/oracle-secure.svg" />
            <div class="roadmap-grid-item-title">Secure Pricing Strategies</div>
            <div class="roadmap-grid-item-description">
              To keep our oracle secure, we have designed both off-chain and
              on-chain data protection mechanisms.
            </div>
          </div>
          <div
            class="roadmap-grid-item oracle-grid-item oracle-grid-item-phase3"
          >
            <img src="../assets/oracle/oracle-seamless.svg" />
            <div class="roadmap-grid-item-title">Seamless and Low Cost</div>
            <div class="roadmap-grid-item-description">
              Oracles are easy to integrate into inscription and NFT-backed DeFi
              projects and decrease 90% on-chain cost.
            </div>
          </div>
        </div>
      </div>
      <section class="feeds">
        <Title info="Explore Floor Price Feeds"></Title>
        <img class="oracle-mainnet-img" :src="OracleMainNet" alt="" />
      </section>
    </section>
  </div>
</template>
<script>
import waveMore from '@/components/waveMore'
import Title from '@/components/Title'

import vector from '@/assets/img/vector.svg'
import polygon from '@/assets/img/polygon.png'
import m from '@/assets/img/m.svg'
import OracleMultipleDataSources from '@/assets/oracle/oracle-Multiple-Data-Sources.svg'
import OracleSecure from '@/assets/oracle/oracle-secure.svg'
import OracleSeamless from '@/assets/oracle/oracle-seamless.svg'
import OracleMainNet from '@/assets/home/oracle-mainnet.png'

import line from '@/assets/img/line-new.svg'

import store from '../store'

export default {
  name: 'FinancePage',
  data() {
    return {
      store,
      activeIndex: '',
      clientHeight: '',
      isShow: false,
      open: false,
      mouseenter: false,
      isTop: false,
      active: 0,
      showModal: false,
      teams: [''][''],
      modal: null,
      vector: vector,
      polygon: polygon,
      m: m,
      line: line,
      OracleMultipleDataSources: OracleMultipleDataSources,
      OracleSecure: OracleSecure,
      OracleSeamless: OracleSeamless,
      OracleMainNet: OracleMainNet
    }
  },
  components: {
    waveMore,
    Title
  },
  mounted() {
    let clientHeight = `${document.documentElement.clientHeight}`
    this.clientHeight = `${clientHeight}` + 'px'
    window.addEventListener('scroll', this.handleScroll)
    window.scroll({
      top: 0
    })
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let navoffsetTop = 274

      if (scrollTop > navoffsetTop) {
        document.querySelector('.navbar').style.backgroundColor = '#000'
      } else {
        document.querySelector('.navbar').style.backgroundColor = 'transparent'
      }
    },
    goAnchor(selector) {
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth'
      })
    },

    modalOpen: function (i) {
      //let myModal = new Modal(document.getElementById('exampleModal'));
      this.modal.show()
      //this.showModal = true;
      i.isShow = true
      //this.teams = i;
      //return this.active = i;
    },
    hideModal() {
      this.modal.hide()
    },
    swichProtocolSlides() {
      this.activeProtocolInterval = setInterval(() => {
        this.activeProtocol.active = true
        if (this.activeProtocol.active && this.activeProtocol.index < 3) {
          this.activeProtocol.index++
        } else {
          this.activeProtocol.index = 1
        }
      }, 1500)
    },
    destroyProtocolInterval() {
      this.activeProtocol = { active: false, index: 0 }
      this.activeProtocolInterval = null
    }
  }
}
</script>

<style lang="less" scoped>
.roadmap-grid-item-title {
  margin-bottom: 69px;
}
.top-description-wrapper {
  padding: 0 20px;

  p {
    max-width: 320px;
  }
}
.community {
  padding: 100px 20px 220px 20px;
  max-width: 863px;
}

.feeds {
  padding: 0 20px 0 20px;
  margin: auto;
  max-width: 863px;
}

.oracle-mainnet-img {
  width: 100%;
  margin-top: 52px;
}

.line-finance {
  width: auto;
}

.audit-row {
  justify-content: center;
}
.oracle-grid-item {
  position: relative;
  overflow: hidden;

  & > img {
    position: absolute;
    left: 0;
    bottom: 0;
    transform-origin: left bottom;
    opacity: 0.2;
  }
}
.oracle-grid-item-phase1 {
  background-image: linear-gradient(
    180deg,
    #1c0200 50%,
    rgba(28, 2, 0, 0.5) 100%
  );
}

.oracle-grid-item-phase2 {
  background-image: linear-gradient(
    180deg,
    #1c0200 50%,
    rgba(28, 2, 0, 0.5) 100%
  );
}

.oracle-grid-item-phase3 {
  background-image: linear-gradient(
    180deg,
    #1c0200 50%,
    rgba(28, 2, 0, 0.5) 100%
  );
}

@media all and (min-width: 768px) {
  .audit-row {
    justify-content: flex-start;
  }
  .oracle-mainnet-img {
    max-width: 945px;
  }

  .top-description-wrapper {
    padding: 0;
    text-align: left;
    padding-left: 7rem;
  }
}
</style>
