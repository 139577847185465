<template>
  <div class="title-container">
    <p
      :class="{
        'title-ifo': true,
        uppercase: uppercase,
        'text-align-left': textAlignLeft,
      }"
      v-html="info"
    ></p>
  </div>
</template>
<script>
export default {
  name: 'TitleCom',
  props: ['info', 'uppercase', 'textAlignLeft'],
};
</script>

<style scoped>
/* .title-container {
  background-image: url(../assets/img/polygon.png);
  background-repeat: no-repeat;
  background-position: center bottom;
} */
.title-ifo {
  font-weight: 900;
  font-size: 46px;
  line-height: 66px;
  text-align: center;
  color: #f94432;
}
.uppercase {
  text-transform: uppercase;
}

@media all and (min-width: 992px) {
  .text-align-left {
    text-align: left;
  }
}
</style>
