<template>
  <svg
    width="77"
    height="87"
    viewBox="0 0 77 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="play-btn"
  >
    <path
      d="M73.5094 47.566C76.8402 45.6411 76.8403 40.833 73.5096 38.908L8.50199 1.3359C5.16866 -0.590645 0.999996 1.81485 0.999996 5.66488L1 80.8049C1 84.6548 5.16846 87.0603 8.50179 85.134L73.5094 47.566Z"
      fill="url(#paint0_linear_6943_109)"
      stroke="#F94432"
      stroke-miterlimit="10"
      class="play-default"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6943_109"
        x1="13.8155"
        y1="42.7858"
        x2="61.1942"
        y2="42.7858"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F94432" />
        <stop offset="1" stop-color="#F94432" stop-opacity="0" />
      </linearGradient>
    </defs>
    <path
      d="M73.5094 47.566C76.8402 45.6411 76.8403 40.833 73.5096 38.908L8.50199 1.3359C5.16866 -0.590645 0.999996 1.81485 0.999996 5.66488L1 80.8049C1 84.6548 5.16846 87.0603 8.50179 85.134L73.5094 47.566Z"
      fill="url(#paint0_linear_6943_110)"
      stroke="#F94432"
      stroke-miterlimit="10"
      class="play-hover"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6943_110"
        x1="0.999998"
        y1="43.2347"
        x2="81"
        y2="43.2347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#621813" />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style lang="less" scoped>
.play-hover {
  display: none;
}
.play-btn {
  cursor: pointer;

  &:hover {
    .play-hover {
      display: block;
    }
    .play-default {
      display: none;
    }
  }
}
</style>
