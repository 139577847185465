import { createRouter, createWebHistory } from 'vue-router';
//import Home from '../views/Home.vue'
import HomeNew from '../views/HomeNew.vue';
import Finance from '../views/Finance.vue';
import Oracle from '../views/Oracle.vue';
import About from '../views/About.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeNew,
  },
  {
    path: '/finance',
    name: 'Finance',
    component: Finance,
  },
  {
    path: '/oracle',
    name: 'Oracle',
    component: Oracle,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
